import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import AppConfig from '../appconfig';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import {
  reset
} from "../state/actions";



class ResetPassword extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			formLoading: false,
			resetPassFeedback: '',
			passwordInvalid: "",
			email: '',
			finishedFeedback: '',
			token: ''
		};

		this.handleChangePassword = this.handleChangePassword.bind(this);
		this.checkResetPassToken = this.checkResetPassToken.bind(this);
		this.handleChangePassword = this.handleChangePassword.bind(this);
		this.changePassword = this.changePassword.bind(this);
	}

	componentDidMount() {
		if (!this.props.loggedIn) {
			let token = this.getTokenFromQueryString();
			this.checkResetPassToken(token);
		}
	}

	getTokenFromQueryString() {
		var query = this.props.location.search.substring(1);
		var vars = query.split("&");
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split("=");
			if (pair[0] === "token") {
				return pair[1];
			}
		}
		return false;
	}


	handleChangePassword(event) {
		if (this.state.formLoading) {
			return;
		}

		const form = event.currentTarget;

		if (form.elements.password.value != form.elements.confirmPassword.value) {
			this.setState({
				passwordInvalid: "true"
			});
			return;
		} else {
			this.setState({
				formLoading: true,
				passwordInvalid: ''
			});

			this.changePassword(form.elements.password.value);
		}
		
		event.preventDefault();
		event.stopPropagation();
	}

	changePassword(password) {
		let url = AppConfig.API_URL + 'resetPassWithToken';

		url += ('/' + this.state.token);
		url += ('/' + password);

	 	fetch(url, {
	 		credentials: 'include'
	 	})
		.then(res => res.json())
		.then(
			result => {
				if (result.success) {
					this.setState({
						finishedFeedback: 'Ditt lösenord har ändrats och du kan nu logga in!',
						formLoading: false,
						resetPassFeedback: ''
					});
				} else {
					let feedback = 'Kunde inte byta lösenord. Försök igen senare.';
					if (result.errorCode == 3) {
						feedback = 'Lösenordet måste vara minst 8 tecken långt.'
					}
					this.setState({
						resetPassFeedback: feedback,
						formLoading: false
					});
				}
			},
			error => {
				this.setState({
					resetPassFeedback: 'Kunde inte byta lösenord. Försök igen senare.',
					formLoading: false
				});
			}
		);

		return false;
	}

	checkResetPassToken(token) {
		let url = AppConfig.API_URL + 'checkResetPassToken';

		url += ('/' + token);

	 	fetch(url, {
	 		credentials: 'include'
	 	})
		.then(res => res.json())
		.then(
			result => {
				if (result.success) {
					this.setState({
						token: token,
						isLoading: false
					});
				} else {
					this.setState({
						finishedFeedback: 'Din länk var inte giltig. Var vänlig försök igen.',
						isLoading: false
					});
				}
			},
			error => {
				this.setState({
					finishedFeedback: 'Din länk var inte giltig. Var vänlig försök igen.',
					isLoading: false
				});
			}
		);

		return false;
	}

 	render() {

 		if (this.props.loggedIn) {
        	return (
				<div className="chatPageWrapper page">
					<div className="errorText">Du är redan inloggad!</div>
				</div>
			);
        } else if (this.state.isLoading) {
			return (
				<div className={'chatPageWrapper page'}>
					<div className="col-xl-8 order-xl-1 mb-5 resetWrapper">
						<Spinner className={'roomLoader'} animation="border" size="xl" variant="primary" />
					</div>
				</div>
			);
        } else {
        	return(
        		<div>
		        	{this.state.finishedFeedback != undefined && this.state.finishedFeedback != '' ? 
		        		<div className="chatPageWrapper page">
							<div className="errorText">{this.state.finishedFeedback}</div>
						</div>

						: 

						<div className="chatPageWrapper page">
							<div className="col-xl-8 order-xl-1 mb-5 resetWrapper">
								<div className="card settings cardShadow">
									<div className="card-header bg-white border-0">
										<div className="row">
											<div className="col-8 resetTitle">
												<h6 className="mb-0">Återställ ditt lösenord</h6>
											</div>	
										</div>
									</div>
									<div className="card-body">
						             
							            <Form onSubmit={this.handleChangePassword}>
											<Form.Group controlId="validationCustom04">
												<Form.Label>Lösenord</Form.Label>
												<Form.Control name="password" type="password" minLength="8" placeholder="Lösenord" required />
												<Form.Control.Feedback type="invalid">
													Välj ett lösenord.
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group controlId="validationCustom04">
												<Form.Label>Bekräfta Lösenord</Form.Label>
												<Form.Control name="confirmPassword" isInvalid={this.state.passwordInvalid} type="password" placeholder="Lösenord" required />
												<Form.Control.Feedback type="invalid">
													Lösenorden matchar inte
												</Form.Control.Feedback>
											</Form.Group>

											<p className="feedback">{this.state.resetPassFeedback}</p>
											<Button type="submit" disabled={this.state.formLoading}>{this.state.formLoading ? 'Sparar..' : 'Spara'}</Button>
										</Form>
						            </div>
								</div>
							</div>
						</div>
					}
				</div>   		
			);
        }      
		
	}
}

function mapStateToProps(state) {
	const { loggedIn } = state
	return { loggedIn: loggedIn }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		reset: reset
	},
	dispatch
	);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ResetPassword);